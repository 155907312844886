export interface ImageProps {
    readonly imagePath: string;
    readonly imageAlt: string;
}
function CircularImage(props: ImageProps) {
    return (
        <div className="circular-image-wrap">
            <div className="circular-image">
                <img className="circular-image-img" src={props.imagePath} alt={props.imageAlt} />
            </div>
        </div>
    )
}

export default CircularImage;
