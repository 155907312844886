import LinkButton from "./LinkButton";

function LinksView() {
    return(
        <div className="links-view">
            <LinkButton title={"UK Apprenticeships"} linkUrl={"https://www.apprenticeships.gov.uk"} imagePath={"apprenticeship-logo.png"} hoverHexBackgroundColor={"#000"} hoverHexTextColor={"#FFF"} />
            <LinkButton title={"Amazon Apprenticeships"} linkUrl={"https://www.amazonapprenticeships.co.uk"} imagePath={"amzn-smile.png"} hoverHexBackgroundColor={"#FF9900"} hoverHexTextColor={"#232F3E"} />
            <LinkButton title={"TikTok (Apprenticeship Videos)"} linkUrl={"https://www.tiktok.com/@0li.v.errr"} imagePath={"https://s3.eu-west-1.amazonaws.com/redfm.ie/wp-content/uploads/2022/06/09193844/TikTok.png"} hoverHexBackgroundColor={"#f81776"} hoverHexTextColor={"#FFF"} />
            <LinkButton title={"Reach out to me (LinkedIn)"} linkUrl={"https://www.linkedin.com/in/oliwheatley"} imagePath={"linkedin-logo.png"} hoverHexBackgroundColor={"#FFF"} hoverHexTextColor={"#232F3E"} />
        </div>
    );
}

export default LinksView;
