import CircularImage from "../components/CircularImage";
import LinksView from "../components/LinksView";
import React from "react";

function MainPage() {
    return(
        <div className="main-page-container">
            <div className="main-page">
                <div className="title-container">
                </div>
                <div className="info-view-container">
                    <div className="image-section">
                        <CircularImage imagePath="oli-wheatley-amzn.jpeg" imageAlt="Placeholder Image"/>
                        <p className="name-text"><b>Oli Wheatley</b></p>
                    </div>
                    <div className="text-section">
                        <h1>My Story:</h1>
                        <br/>
                        <p className="para">Upon leaving college in 2019, I wanted to continue my education for a
                            degree, however I also wanted to get straight into work. I stumbled onto apprenticeships, a
                            work based educational program where a student will spend 80% of their time working in a
                            real industry position while spending the remaining 20% at college or university. The
                            education is fully funded and the apprentice earns a salary.
                            <br/><br/> In September 2019, I joined Amazon as a Software Development Engineer Degree
                            Apprentice. Now, in my final year of the program, I hope to inspire you into becoming an
                            apprentice. Use the links below to learn more about apprenticeships or if you have any
                            specific questions, reach out on LinkedIn. </p>
                    </div>
                </div>
                <div className="links-container">
                    <LinksView/>
                </div>
            </div>
            <p className="small-text">All opinions are my own and do not reflect that of Amazon. This website is not
                managed by Amazon.</p>
        </div>
    )
}

export default MainPage;
