import styled from "styled-components";

export interface LinkButtonProps {
    readonly title: string;
    readonly linkUrl: string;
    readonly imagePath: string;
    readonly hoverHexBackgroundColor: string;
    readonly hoverHexTextColor: string
}

function LinkButton(props: LinkButtonProps) {

    const Link = styled.button`
          display: flex;
          width: 100%;
          margin-top: 25px;
          align-items: center;
          height: fit-content;
          background: #232F3E;
          transition: all 1s;
          border: solid rgba(184, 184, 184, 0.398) 1px;
          text-align: center;
          box-shadow: 0px 0px 2px 0.5px #2c2c2cc9;
          bottom:0.5px;
          z-index:-1;
          
          &:hover {
            cursor: pointer;
            box-shadow: 1px 1px 25px 10px rgba(255,255,255,.1);
            background: ${props.hoverHexBackgroundColor};
    
            .link-text {
              color: ${props.hoverHexTextColor};
            }
          }
        `;

    return (
        <div className="link-wrap">
            <a href={props.linkUrl} target="_blank" rel="noreferrer" className="link-tag">
                <Link>
                    <div className="link-image-wrap">
                        <img src={props.imagePath} className="link-image" alt="Social Logo Icon"/>
                    </div>
                    <p className="link-text">{props.title}</p>
                </Link>
            </a>
        </div>
    )
}

export default LinkButton;
