import LinkButton from "../components/LinkButton";
import RectImage from "../components/RectImage";

function StepUpPage() {
    return (
        <div className="main-page-container">
            <div className="main-page">
                <div>
                    <p className="name-text"><b>Here is everything you need to get started on your website building
                        adventure!</b></p>
                </div>
                <div>
                    <div className="links-view">
                        <LinkButton title={"React Code Generator"}
                                    linkUrl={"https://partyrock.aws/u/owheatle/b8oWWGKQ4/React-Code-Composer"}
                                    imagePath={"amzn-smile.png"} hoverHexBackgroundColor={"#000"}
                                    hoverHexTextColor={"#FFF"}/>
                        <LinkButton title={"React Playground"} linkUrl={"https://playcode.io/react"}
                                    imagePath={"amzn-smile.png"} hoverHexBackgroundColor={"#000"}
                                    hoverHexTextColor={"#FFF"}/>
                    </div>
                </div>
                <div className="small-text">
                    <p className="small-text">Remember that you need to ask the chat bot to show you the code after you
                        change the input boxes.</p>
                </div>
                <div className="small-text">
                    <p><b>Instructions when coding:</b></p>
                    <ol>
                        <li><p>Generate the code you need from the PartyRock Code Generator</p></li>
                        <li><p>Copy the code and paste it into a new file in the src folder in React Playground</p></li>
                        <li><p>Inside App.jsx import your component with the code -&gt; import ComponentName from
                            './FileName'</p></li>
                        <li><p>Add the Component to the page as many times as you like by adding the tag to the HTML like this: {'<'}ComponentName/&gt;</p></li>
                    </ol>
                </div>
            </div>
        </div>
    )
}

export default StepUpPage;
