import React from 'react';
import './App.css';
import MainPage from "./pages/MainPage";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import StepUpPage from "./pages/StepUpPage";

const router = createBrowserRouter([
    {
        path: "/",
        element: <MainPage />,
        errorElement: <MainPage />,
    },
    {
        path: "step-up",
        element: <StepUpPage />,
    },
    {
        path: "stepup",
        element: <StepUpPage />,
    },
]);

function App() {
    return (
        <div className="App">
            <RouterProvider router={router} />
        </div>
    );
}

export default App;
